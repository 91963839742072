//import Index from '../Index'
import {check_login} from '@/helper/function'

export default [
    {
        path: '/guests',
        name: 'guests',
        beforeEnter: (to, from, next) => {
            check_login(to, from, next)
        },
        component: () => import('../Index.vue'),
        children : [
            {
                path: 'index',
                name: 'guest_index',
                component: () => import('@/components/common/guest/Guest.vue')
            },
            {
                path: 'payment',
                name: 'guest_payment_history',
                component: () => import('@/components/common/guest/PaymentHistory.vue')
            },
            {
                path: 'tips',
                name: 'social_tip',
                component: () => import('@/components/common/guest/Tips.vue')
            },
            {
                path: 'tinimce-test',
                name: 'tinimce_test',
                component: () => import('@/components/common/guest/TestTinimce.vue')
            }
        ]
    }
]