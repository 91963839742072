<template>
    <div id="app_left">
        <div class="uinfo">
            <router-link class="home" :to="'/'">
                <span class="f22 flaticon-home"></span>
            </router-link>
        </div>
        <ul class="menu">
            <li v-for="(link, i) in links" 
                :key="i" 
                :class="[$route.path == link.to ? 'nav-active' : '']" >
                <router-link v-if="link.show" :to="link.to">
                    <span class="navicon" :class="link.icon"></span>
                    <span>{{ link.text }}</span>
                </router-link>
            </li>            
        </ul>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name : 'menu_left',
    data () {
        return {            
            links: [                        
                        
                        {
                            to: '/dictionary',
                            icon: 'flaticon-database',
                            text: 'Dictionary',
                            key: 'dictionary', /** key permisstion */
                            show: false
                        },
                        {
                            to: '/new-dictionary',
                            icon: 'flaticon-database',
                            text: 'New Dict',
                            key: 'new_dict', /** key permisstion */
                            show: false
                        },
                        {
                            to: '/profile',
                            icon: 'flaticon-wheel',
                            text: 'Quản trị',
                            key: 'manager', /** key permisstion */
                            show: true
                        },
                        
                    ],
            permission: {}, /** quyền truy cập của nhân viên */
        }
    },
    created(){
        //console.log(this.$route)
        //console.log('menu',this.get_user_permission)
    },
    computed:{
        /**
         * get_notifychat: lấy tông tin nhắn chưa trả lời trong store
         */
        ...mapGetters(['get_user_permission', 'getUser'])
    },

    methods: {
        replace_menu(){
            
            if(this.getUser.id != 1){
                for(let permission_key in this.get_user_permission){
                    for(let vitri in this.links){
                        if(this.links[vitri].key == permission_key){
                            this.links[vitri].show = true
                        }
                    }
                }
            }else{
                for(let vitri in this.links){
                    this.links[vitri].show = true
                }
            }
        }
    },
    watch:{
        
        
        '$route' : function(){
            //console.log(this.$route)
        },
        

    },

    mounted(){
        this.replace_menu()
    }
    
}
</script>
<style>
.uinfo{
    border-bottom: 1px solid #3378ef;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    height: 50px;
}
.home{
    color: #fff;
    width: 100%;
    height: 100%;
    display: block;
    line-height: 50px;
    text-decoration: none;
}
.home:hover{
    color:#fff;
    background-color: #1B6AF3;
}
.menu{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
}
.menu li{
    padding: 0px;
    text-align: center;
    position: relative;
}
span.notifychat {
    position: absolute;
    right: 5px;
    top: 11px;
    background-color: #f00;
    display: inline-block !important;
    width: auto !important;
    color: #fff !important;
    padding: 0px 4px;
    border-radius: 10px;
    font-size: 11px !important;
}
.menu li a{
    color: #fff;
    display: block;
    overflow: hidden;
    padding: 8px 0;
    text-decoration: none;
}
.menu li:hover{
    color: #fff;
    background-color: #1B6AF3;    
}
.menu li:hover *{
    color: #fff;
}
.nav-active{
    background-color: #1b6af3 !important;    
}
.nav-active *{
    color: #fff !important;
}
.navicon::before{
    font-size: 21px !important;
}
.menu li a span{
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 11px;
}
</style>
