import { concat } from 'lodash'
import { createRouter, createWebHashHistory } from 'vue-router'
import RouterLogin from '@/components/login/router'
import RouterGuest from '@/components/guest/router'
import RouterIndex from '@/components/index/router'
import RouterProfile from '@/components/profile/router'
import RouterReportError from '@/components/report_error/router'


import RouterDictionary from '@/components/dictionary/router'
import RouterNewDictionary from '@/components/new_dictionary/router'
import RouterIelts from '@/components/ielts/router'


const router = createRouter({
	mode: 'history', // hash: Chế độ này sẽ sử dụng dấu hash (#) trong URL
	history: createWebHashHistory(process.env.BASE_URL),
	routes: concat(
		RouterGuest,
		RouterLogin,
		RouterIndex,
		RouterProfile,
		RouterReportError,

		RouterDictionary,
		RouterNewDictionary,
		RouterIelts
	)
})

export default router
