import {check_login} from '@/helper/function'

export default [
    {
        path: '/dictionary',
        name: 'dictionary',
        beforeEnter: (to, from, next) => {
            check_login(to, from, next)
        },
        component: () => import('../Index.vue')
    }
]