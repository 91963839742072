//import Index from '../Index'
import {check_login} from '@/helper/function'

export default [
    {
        path: '/',
        name: 'index',     
        beforeEnter: (to, from, next) => {
            check_login(to, from, next)
        },   
        component: () => import('../Index.vue')
    }
]