import store from '@/store'
import router from '@/router'
import axios from "axios"

/** function check login trước khi vào 1 router */
export function check_login(to, from, next){
    if(to.name != 'login'){
        let ls = localStorage
        let token = ls.getItem('chinese_admin_token')
        if(store.state.logged || token != null){
            next()
        }else{
            router.push({name: 'login'})
        }               
    }else{
        next()
    }
    
}

export function callApi(isurl = '', param = {}, ismethod = 'POST'){
    let ls = localStorage
    let token = ls.getItem('chinese_admin_token')
    if(token != ''){
        return axios({
            method: ismethod,
            baseURL: process.env.VUE_APP_BASE_URL,
            url: isurl,
            data: param,
            headers:{
                'token': token
            }
        })
    }else{
        alert('Bạn cần đăng nhập')
    }
    
}


// đọc lỗi từ axios catch
export function readError(obj){
    let msg_err = ''
    if(obj.response != undefined && obj.response != 'undefined'){
        let ecode,etext
        if(typeof obj.status != 'undefined') ecode = obj.status
        if(typeof obj.statusText != 'undefined') etext = obj.statusText
        msg_err = 'Code: '+ ecode +' = Lỗi: ' + etext
    }else{
        msg_err = obj
    }
    return msg_err
}

/** tạo dữ liệu page */
export function generate_page(obj){
    let page = []
    let total_page = 1
    if('total_pages' in obj) total_page = obj.total_pages
    if('last_page' in obj) total_page = obj.last_page
    let current_page = obj.current_page
    if(total_page <= 10){
        for(let i = 1; i <= total_page; i++){
            page.push({
                page: i,
                name: i,
                title: 'Trang '+ i,
                class: (current_page == i)? 'page_current' : ''
            })
        }
    }else{
        let startpage = (current_page - 3 >= 0)? current_page - 3 : 1
        let endpage = (current_page + 3 <= total_page)? current_page + 3 : total_page
        page.push({
            page: 1,
            name: '«',
            title: 'Trang đầu'
        })
        for(let i = startpage; i<= endpage; i++){
            page.push({
                page: i,
                name: i,
                title: 'Trang '+ i,
                class: (current_page == i)? 'page_current' : ''
            })
        }
        page.push({
            page: total_page,
            name: '»',
            title: 'Trang cuối'
        })
    }

    return page
}

export function formatPrice(value) {
    let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function callOutApi(isurl = '', param = {}, ismethod = 'POST'){
    let current_lang = 'vn'
    if( typeof window.currentLang !== 'undefined'){
        current_lang = window.currentLang
    }
    return axios({
        method: ismethod,
        baseURL: process.env.VUE_APP_OUT_URL,
        url: isurl,
        data: param,
        headers:{
            lang: current_lang
        }
    })
    
}

export function formatNumber(value, n = 0){
    if(typeof value == 'number'){
        var re = '\\d(?=(\\d{' + (3) + '})+' + (n > 0 ? '\\.' : '$') + ')';    
        return value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
    }else{
        return value
    }
}