import Router from '@/router'
import { createStore } from 'vuex'
//import storeChat from '@/components/chat/store'


const store = createStore({
    state: {
        // thông tin user
        user:{
            id:0,
            login:'',
            fullname:'',
            create_at:'',
            is_payment:0,
            payment_expires:'',
            avatar:'',
            phone:'',
            admin_id:''
        },
        notifi: {}, /** thông tin notifi */
        token:'', // token gọi api của user
        logged: false, // trạng thái login
        staff:{}, // thông tin các nhân viên trong cùng tài khoản
        guest_id: 0, // id khách hàng đang mở chat
        show_alert_success: false, /** hiển thị thông báo lỗi */
        show_alert_error: false,
        alert_msg_success: '', /** tin nhắn thành công */
        alert_msg_error: '', /** tin nhắn lỗi */
        permission: {}, /** mảng phân quyền theo từng gói */
        package: 0, /** gói đang dùng là gói nào */
        default_permission: {}, /** mảng phân quyền của tk */
        user_permission: {}, /** quyền của user trong admin */

    },
    mutations: {

        // this.$store.commit(mutation function, data)
        // function sẽ cập nhật thông tin vào state
        /**
         * 
         * @param {*} state 
         * @param {*} data {
         *  token: 
         *  user:
         * }
         * 
         */
        setLogin(state, response){
            if(response != null){
                let data = response.data
                let code = response.code
                let token = response.token
                if(code == 200){
                    state.logged = true // set đăng nhập
                    // lưu thông tin vào localstorage và redirect về trang chat
                    let ls = localStorage
                    ls.setItem('chinese_admin_user', JSON.stringify(data));

                    // phân tích dữ liệu data để gắn vào state
                    state.user  = data
                    state.token = token
                    ls.setItem('chinese_admin_token', token)
                    // chuyển đến trang dashboad
                    Router.push({name: 'index'})
                    //window.location.reload()                    

                } // end check code == 200
                
            }
        },

        /**
         * set logout
         */
        setLogout(state){
            let ls = localStorage
            ls.removeItem('chinese_admin_token')
            ls.removeItem('chinese_admin_user')
            state.logged = false
            Router.push({name: 'login'})
        },

        
        /** gắn lại thông tin user khi vào trang app.vue */
        set_user(state, data){
            state.user = data
            let ls = localStorage
            ls.setItem('chinese_admin_user', JSON.stringify(data));
        },

        
        /** set show alert success */
        set_alert_msg_success(state, data){
            state.alert_msg_success = data
            state.show_alert_success = true
            setTimeout(function(){
                state.show_alert_success = false
                state.alert_msg_success = ''
            }, 5000)
        },
        set_alert_msg_error(state, data){
            state.alert_msg_error = data
            state.show_alert_error = true
            setTimeout(function(){
                state.show_alert_error = false
                state.alert_msg_error = ''
            }, 5000)
        },

        

        /** lưu permisstion */
        set_permission(state, data){
            state.permission  = data
        },

        set_package(state, data){
            state.package = data
        },

        
        /** set default quyền khi App.vue được load */
        set_default_permission(state,data){
            state.default_permission = Object.assign({}, data)
        },

        /** set quyền cho user khi App.vue được load */
        set_user_permission(state, data){
            state.user_permission = Object.assign({}, data)
        }
    },
    getters: {
        
        /**
         * 
         * getter sẽ láy lấy ra các biến của state
         */

        
        /** trả ra thông tin user */
        getUser(state){
            return state.user
        },

        
        get_alert_msg_error(state){
            return state.alert_msg_error
        },
        get_alert_msg_success(state){
            return state.alert_msg_success
        },
        get_show_alert_error(state){
            return state.show_alert_error
        },
        get_show_alert_success(state){
            return state.show_alert_success
        },
        /** lấy permission theo từng gói */
        get_permission(state){
            return state.permission
        },

        /** lấy thong tin gói */
        get_package(state){
            return state.package
        },

        /** lấy quyền mặc định để admin set cho user */
        get_default_permission(state){
            return state.default_permission
        },

        /** get quyền của nhân viên */
        get_user_permission(state){
            return state.user_permission
        }



    },
    actions: {
        /**
         * 
         * Function trong action đẻ các component gọi theo kiểu
         * ...mapaction()
         * hàm trong này sẽ gọi lên mutation bằng hàm commit
         * dùng ...mapaction() để trong component có thể gọi theo kiểu this.function_name() giống như action này là methode của component
         */

        set_visitor_id({commit}, value){
            commit('set_visitor_id', value)
        },

        set_loged({commit}, value){
            commit('setLoged', value)
        }
    },
    modules: {
        //storeChat
    }
})

export default store