<template>
	<div  v-if="$route.name == 'login' || $route.name == 'register' || $route.name == null">
		<router-view></router-view>
	</div>

	<div class="flex h100" v-else>
		<menu-left></menu-left>
		<div id="app_right">
			<router-view></router-view>		
			<div class="alert alert-warning alert_msg" role="alert" v-if="get_show_alert_error()">{{get_alert_msg_error()}}</div>
            <div class="alert alert-success alert_msg" role="alert" v-if="get_show_alert_success()">{{get_alert_msg_success()}}</div>	
		</div>
	</div>
</template>

<script>
import MenuLeft from  './components/common/MenuLeft'
import {mapGetters} from 'vuex'
export default {
	name: 'App',
	components: {
	MenuLeft
	},
	data(){
		return {
			my_title: process.env.VUE_APP_TITLE
		}
	},

	created(){
		let ls = localStorage
		let data_user = ls.getItem('chinese_admin_user')

		if(data_user == null){
			this.$router.push({name: 'login'})
		}else{
			data_user = JSON.parse(data_user)
			this.$store.commit('set_user', data_user)

			/** lấy quyền từ localstorage xem có không */
			if('permission' in data_user){
				this.$store.commit('set_user_permission', data_user.permission)
			}
		}
		

	},

	methods: {
		...mapGetters([
			'get_alert_msg_error',
			'get_alert_msg_success',
			'get_show_alert_error',
			'get_show_alert_success'
		]),
		
	}

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
