import {check_login} from '@/helper/function'

export default [
    {
        path: '/detail-test-kit/:id',
        name: 'detail-test-kit',
        beforeEnter: (to, from, next) => {
            check_login(to, from, next)
        },
        component: () => import('../Index.vue')
    }
]