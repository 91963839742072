import {check_login} from '@/helper/function'
export default [
    {
        path: '/profile',
        name: 'profile',
        beforeEnter: (to, from, next) => {
            check_login(to, from, next)
        },
        component: () => import('../Index.vue'),
        children : [
            {
                path: 'account',
                name: 'account',
                component: () => import('@/components/common/profile/Account.vue')
            },
            {
                path: 'staff',
                name: 'staff',
                component: () => import('@/components/common/profile/Staff.vue')
            },
            {
                path: 'logout',
                name: 'logout',
                component: () => import('@/components/common/profile/Logout.vue')
            }
        ]
    }
]