import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { formatNumber } from './helper/function'

import '@/assets/css/bootstrap.min.css'
import '@/assets/icon/font/flaticon.css'
import '@/assets/css/common.scss'


const myApp = createApp(App);
myApp.use(router)
.use(store)
.use(VueAxios, axios)
.mount('#app');


const myPlugin = {
    install() {
        myApp.config.globalProperties.formatNumber = formatNumber;
    }
}
myApp.use(myPlugin)
